import React from 'react';
//import { Script } from 'gatsby';
import { Helmet } from 'react-helmet';

// Inject "https://donorbox.org/widget.js" into the page via react-helmet and render the donorbox iframe
export const Donorbox = ({ campaign }) => {
  return (
    <div>
      <Helmet>
        <script async src="https://donorbox.org/widget.js" paypalExpress="false"></script>
      </Helmet>
      <iframe
        src={`https://donorbox.org/embed/${campaign}`}
        height="685px"
        width="100%"
        style={{ maxWidth: '500px', minWidth: '310px', margin: 'auto' }}
        seamless="seamless"
        name="donorbox"
        frameBorder="0"
        scrolling="no"
        allowpaymentrequest
      ></iframe>
    </div>
  );
};
